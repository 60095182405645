
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        centerContent: {
            type: Boolean,
            default: false
        }
    }
});
