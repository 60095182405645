import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-204f930a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "greeting-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["col-12", {
            'text-center d-flex flex-column align-items-center': _ctx.centerContent
        }])
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(["mt-3 mb-0 font-weight-bold", { 'mb-3': !_ctx.message }])
    }, _toDisplayString(_ctx.title), 3),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true)
  ], 2))
}